.editable-row .ant-form-item-control {
  line-height: 0;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 2px 9px;
  cursor: pointer;
  min-height: 26px;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 1px 7px;
}
